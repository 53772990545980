import authentication from '@kdpw/msal-b2c-react'

  export const getCachedUser = () => {
    return authentication.getIdToken();
  };
  
   export const acquireToken = () => {
    authentication.getAccessToken();
  };
  export const LogOut = () => {
    authentication.signOut();
};

  