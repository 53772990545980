import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap';
import adminAvatar from '../../assets/avatar.png';

export class DriverdetailsCon extends Component {

    render() {
        const isCheckDrvDetails = this.props.location;
        return (
            <>
                {isCheckDrvDetails.state === 'DD-row-details' ?                
                <Row className="DriverdetailsCon-outr">
                    <Col xs={12} className="DDC-DIM-Con">
                        <Col><img src={adminAvatar} alt="Headerlogo" className="img"/></Col>
                        <Col><div className="DN-DDC-Con">{isCheckDrvDetails.firstName}</div></Col>
                    </Col>                
                    <Col xs={12} className="DDC-DD-Con">
                        <div className="DN-DDC-row">
                            <span>Scheduling system Driver ID:</span>
                            <span>{isCheckDrvDetails.schedulingDriverID}</span>
                        </div>
                        <div className="DN-DDC-row">
                            <span>User ID:</span>
                            <span>{isCheckDrvDetails.userID}</span>
                        </div>
                        <div className="DN-DDC-row">
                            <span>OrgUnit:</span>
                            <span>{isCheckDrvDetails.orgUnit}</span>
                        </div>
                        <div className="DN-DDC-row">
                            <span>Driver Group:</span>
                            <span>{isCheckDrvDetails.driverGroup}</span>
                        </div>
                        <div className="DN-DDC-row">
                            <span>Language:</span>
                            <span>{isCheckDrvDetails.language}</span>
                        </div>
                    </Col>
                </Row>
                : ''
                }
            </>
        )
    }
}

export default DriverdetailsCon
