import React from 'react';


const TextArea = (props) => (  
  <div className="form-group">
    <label className="form-label">{props.title}</label>
    <textarea
      className="form-control"
      name={props.name}
      rows={props.rows}
      cols = {props.cols}
      onChange={props.handleInput}
      placeholder={props.placeholder}
      required={props.isRequired}
      defaultValue={props.value} >
      
      </textarea>
  </div>
);

export default TextArea;