import React, { Component } from "react";
import XLSX from "xlsx";
import { make_cols } from "./MakeColumns";
import { SheetJSFT } from "./types";
import { Button } from "react-bootstrap";
import { getCachedUser } from "../../authContext";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { connect } from "react-redux";
import axios from "axios";
import BulkLoader from "../../Common/BulkLoader";
import ReactTable from "react-table";
import "react-table/react-table.css";

class DeleteBulkTrip extends Component {
  constructor(props) {
    super(props);
    let state = {};
    this.state = {
      file: {},
      data: [],
      cols: [],
      showAlertinvalid: false,
      showsloader: false,
      result: [],
      columns: [],
      showsresult: false,
    };

    // this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this. handleFormSubmit=this. handleFormSubmit.bin(this);
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  }

  //for api call

  handleFormSubmit = (e) => {
    e.preventDefault();
    /* Boilerplate to set up FileReader */
    if (document.getElementById("file").files.length == 0) {
      this.setState({ showAlertinvalid: true });
    } else {
      this.setState({ showsresult: true });
      this.setState({ showsloader: true });
      this.setState({ showAlertinvalid: false });
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          bookVBA: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws);

        if (data.length === 0) {
          this.setState({ showsloader: false });
          this.setState({ showAlertinvaliddata: true });
        } else {
          /* Update state */
          this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {});

          //  let adminUserName = this.props.adminUserName;
          let url = baseURL + "trip/flagerroneoustrips";
          let ishandleDriverkey = "";
          let driverData = this.state.data;
          ishandleDriverkey = "BulkTrip";
          driverData.forEach(function (data, index) {
            data.FlaggedBy = getCachedUser().preferred_username;
            data.Tripid = data.Tripid.toString();
          });
          //     driverData['FlaggedBy'] = "D3PJ39@Linde.Com";
          console.log(JSON.stringify(driverData));

          axios({
            method: "post",
            url: url,
            data: driverData,
            headers: {
              ClientKey: ClientKey,
              UserId: getCachedUser().preferred_username,
              AccessToken: accessToken(),
              RandomToken: this.props.refreshToken,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                this.setState({ showsloader: false });
                let result = response.data;
                this.setState({ result: result });
                this.props.history.push({
                  // pathname: '/',
                  state: ishandleDriverkey,
                });
              }
            })
            .then((data) => console.log())
            .catch((error) => {
              console.log("Error detected: " + error);
              //this.setState({ error: error.response.data });
            });
        }
      };
      if (rABS) {
        reader.readAsBinaryString(this.state.file);
      } else {
        reader.readAsArrayBuffer(this.state.file);
      }
    }
  };

  render() {
    const showAlertinvalid = this.state.showAlertinvalid;
    const showAlertinvaliddata = this.state.showAlertinvaliddata;
    const showsloader = this.state.showsloader;
    const TotalEntry = this.state.data.length;
    const showsresult = this.state.showsresult;
    const FailEntry = this.state.result.length;
    const SuccessEntry = TotalEntry - FailEntry;
    const items = this.state.result.map((item, key) => (
      <li
        key={item.TripNumber}
        style={{
          marginLeft: "35px",
          width: "100%",
          marginBottom: "15px",
          color: "red",
        }}
      >
        {"Trip Number : " + item.TripNumber + "     message: " + item.ErrorDesc}{" "}
      </li>
    ));
    return (
      <div>
        <form className="col-6" onSubmit={this.handleFormSubmit}>
          {showsloader ? (
            <BulkLoader />
          ) : (
            <div>
              <br />
              <div className="form-group">
                <input
                  type="file"
                  accept=".xls,.xlsx"
                  className="form-control"
                  id="file"
                  onChange={this.handleChange}
                  isRequired={true}
                  style={{ marginLeft: "15px", width: "100%" }}
                />
              </div>
              <label
                style={{
                  marginLeft: "15px",
                  width: "100%",
                  marginBottom: "15px",
                  color: "green",
                }}
              >
                Please upload an Excel formatted file with .xlsx extention
              </label>
              {showAlertinvalid ? (
                <div
                  class="alert alert-danger"
                  style={{
                    marginLeft: "15px",
                    width: "100%",
                    marginBottom: "15px",
                  }}
                >
                  Please select file to upload
                </div>
              ) : (
                ""
              )}
              {showAlertinvaliddata ? (
                <div
                  class="alert alert-danger"
                  style={{
                    marginLeft: "15px",
                    width: "100%",
                    marginBottom: "15px",
                  }}
                >
                  Excel sheet need to be filled with appropriate data and column
                  name
                </div>
              ) : (
                ""
              )}

              <br />
              <div className="form-group">
                <Button
                  class="btn btn-primary"
                  type="Submit"
                  style={{
                    marginLeft: "15px",
                    width: "100%",
                    marginBottom: "15px",
                  }}
                >
                  Upload
                </Button>
              </div>
            </div>
          )}
        </form>
        {showsresult ? (
          <div>
            <table class="table" style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Total number of entry</th>
                  <th>Success</th>
                  <th>Fail</th>
                </tr>
              </thead>
              <tbody>
                <tr class="alert alert-info">
                  <td>{TotalEntry}</td>
                  <td>{SuccessEntry}</td>
                  <td>{FailEntry}</td>
                </tr>
              </tbody>
            </table>
            {FailEntry != 0 ? (
              <div>
                <samp
                  class="alert alert-danger"
                  style={{
                    marginLeft: "15px",
                    width: "100%",
                    marginTop: "15px",
                  }}
                >
                  Failed Trip details
                </samp>

                <ul style={{ marginTop: "35px" }}>{items}</ul>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

//export default CreateBulkDriver

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(DeleteBulkTrip);
