import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "../common.css";
import adminAvatar from "../../assets/avatar.png";
import { LogOut, getCachedUser } from "../../authContext";
import { ClientKey, accessToken, baseURL } from "../../Services/API";
import { connect } from "react-redux";
import axios from "axios";
import authentication from "@kdpw/msal-b2c-react";

class Header extends Component {
  state = {
    isOpen: false,
  };

  signOut = () => {
    axios({
      method: "post",
      headers: {
        ClientKey: ClientKey,
        UserId: getCachedUser().preferred_username,
        AccessToken: accessToken(),
        RandomToken: this.props.refreshToken,
      },
      url: `${baseURL}token/logout`,
    })
      .then(function (response) {
        //LogOut();
        authentication.signOut();
      })
      .catch((err) => null);
  };

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  render() {
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
    return (
      <>
        <Row className="Header-Con">
          <Col className="Header-CInnr">
            <p>
              <span>{this.props.adminUserName}</span>
            </p>
            <div className="dropdown" onClick={this.toggleOpen}>
              <button
                className=""
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
              >
                <img src={adminAvatar} alt="Headerlogo" className="img" />
              </button>
              <div className={menuClass} aria-labelledby="dropdownMenuButton">
                <span
                  className="dropdown-item"
                  onClick={this.signOut}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(Header);
