import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import authentication from '@kdpw/msal-b2c-react';
import decodeJWT from 'jwt-decode';

authentication.initialize({
  // optional, will default to this
  instance: 'https://login.microsoftonline.com/',
  // your B2C tenant
  //tenant: process.env.REACT_APP_TENANT,
  tenant: process.env.REACT_APP_ADMIN_TENANT,
  // the policy to use to sign in, can also be a sign up or sign in policy
  signInPolicy: '',
  // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
  applicationId: process.env.REACT_APP_ADMIN_CLIENTID,
  // where MSAL will store state - localStorage or sessionStorage
  cacheLocation: process.env.REACT_APP_CACHELOCATION,
  // the scopes you want included in the access token
  scopes: ['openid'],
  // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
  redirectUri: process.env.REACT_APP_REDIRECTURI,
  // optional, the URI to redirect to after logout
  postLogoutRedirectUri: window.location.origin,
  validateAuthority: false
});
var emails = [];
authentication.run(() => {
  const token = authentication.getAccessToken();
  const decoded = decodeJWT(token);
  emails = decoded.preferred_username
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App email={emails} />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'));
});
