import React, { Component } from "react";
import { Row } from "react-bootstrap";
import Input from "../../Common/Formcomponents/Input";
import Select from "../../Common/Formcomponents/Select";
import axios from "axios";
import TextArea from "../../Common/Formcomponents/TextArea";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { getCachedUser } from "../../authContext";
import { connect } from "react-redux";

class orgUnitIdCreate extends Component {
  constructor(props) {
    super(props);
    let state = {};
    if (props.location.state !== undefined) {
      state = {
        user: {
          id: props.location.state.Id,
          name: props.location.state.Name,
          description: props.location.state.Description,
          distanceUom: props.location.state.DistanceUom,
          fuelUom: props.location.state.FuelUom,
          language: props.location.state.Language,
          volumeUom: props.location.state.VolumeUom,
        },
      };
    } else {
      state = {
        user: {
          name: "",
          description: "",
          distanceUom: "",
          fuelUom: "",
          language: "",
          volumeUom: "",
        },
      };
    }
    state["volumeUom"] = [];
    state["fuelUom"] = [];
    state["language"] = [];
    state["distanceUom"] = [];
    state["error"] = "";
    this.state = state;

    this.handleInput = this.handleInput.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleInput = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState((prevState) => ({
      user: { ...prevState.user, [name]: value },
    }));
  };

  componentDidMount() {
    if (this.props.refreshToken !== "") {
      this.getConfig();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshToken !== this.props.refreshToken) {
      this.getConfig();
    }
  }

  getConfig = () => {
    let url = baseURL + "driver/getconfig";
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let response = json.data;
        let data = response;
        this.setState({
          distanceUom: data.Distance,
          fuelUom: data.Fuel,
          language: data.Language,
          volumeUom: data.Volume,
        });
      });
  };

  handleCheckBox = () => {
    this.setState((prevState) => ({
      user: { ...prevState.user, isActive: !prevState.user.isActive },
    }));
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    let url = "";
    let ishandleDriverkey = "";
    let userData = this.state.user;

    let adminUserName = this.props.adminUserName;
    if (this.props.location.state !== undefined) {
      url = baseURL + "organizationunit/update";
      ishandleDriverkey = "Updated";
      userData["modifiedBy"] = adminUserName;
    } else {
      url = baseURL + "organizationunit/new";
      ishandleDriverkey = "Created";
      userData["createdBy"] = adminUserName;
    }
    axios({
      method: "post",
      url: url,
      data: userData,
      headers: {
        ClientKey: ClientKey,
        UserId: getCachedUser().preferred_username,
        AccessToken: accessToken(),
        RandomToken: this.props.refreshToken,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push({
            pathname: "/manageorgunitid",
            state: ishandleDriverkey,
          });
        }
      })
      .then((data) => console.log())
      .catch((error) => {
        console.log("Error detected: " + error);
        this.setState({ error: "Org Unit is" + " " + error.response.data });
      });
  };

  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      user: {
        name: "",
        description: "",
        distanceUom: "",
        fuelUom: "",
        language: "",
        volumeUom: "",
      },
    });
  }
  render() {
    const isEditfieldsHandled = this.props.location.state === undefined;
    return (
      <Row className="createDriver-Con">
        <h3
          className="col-12"
          style={{ color: "#000000", fontSize: "32px", marginBottom: "1.5rem" }}
        >
          {isEditfieldsHandled ? "Create" : "Edit"} org unit
        </h3>
        <div className="clearfix"></div>
        <div className="text-danger col-12">{this.state.error}</div>
        <div className="clearfix"></div>
        <form className="col-5" onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <Input
              inputtype={"text"}
              title={"Create org unit"}
              name={"name"}
              value={this.state.user.name}
              placeholder={"Enter your Org Unit"}
              handlechange={this.handleInput}
              isRequired={true}
            />
          </div>
          <TextArea
            title={"Description"}
            name={"description"}
            value={this.state.user.description}
            placeholder={"Please enter the description"}
            handleInput={this.handleInput}
            isRequired={true}
            rows={70}
            cols={70}
          />
          <div className="form-group">
            <Select
              title={"Language"}
              name={"language"}
              options={this.state.language}
              value={
                this.state.user.language !== "" ? this.state.user.language : ""
              }
              placeholder={"Select Language"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <Select
              title={"Fuel UOM"}
              name={"fuelUom"}
              options={this.state.fuelUom}
              value={this.state.user.fuelUom ? this.state.user.fuelUom : ""}
              placeholder={"Select Fuel"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <Select
              title={"Distance UOM"}
              name={"distanceUom"}
              options={this.state.distanceUom}
              value={
                this.state.user.distanceUom ? this.state.user.distanceUom : ""
              }
              placeholder={"Select Distance"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>

          <div className="form-group">
            <Select
              title={"Quantity UOM"}
              name={"volumeUom"}
              options={this.state.volumeUom}
              value={this.state.user.volumeUom ? this.state.user.volumeUom : ""}
              placeholder={"Select Quantity"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: "100%" }}
            >
              Submit
            </button>
          </div>
        </form>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(orgUnitIdCreate);
