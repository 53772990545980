import axios from 'axios';
import authentication from '@kdpw/msal-b2c-react';

export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: "json"
});
export const accessToken = () => {
    return authentication.getAccessToken();
  };
export const baseURL = process.env.REACT_APP_API_URL;
export const ClientKey = process.env.REACT_APP_API_CLIENTKEY;
