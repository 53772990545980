import React from 'react';

const LoaderAllDriver = function(){
    return (
        <div>
        <div id="overlay" > 
        <div className="spinner-border text-primary" role="status" style={{width: '3rem', height: '3rem'}} ></div>
        
        <br/>
        <span className="alert alert-info">Please Wait.....</span>
        
    </div>
    
        
           </div>
    );
}

export default LoaderAllDriver;