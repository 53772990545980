import React from 'react';


const Input = (props) => {
	return (  
  <div className="form-group">
    <label htmlFor={props.name} className="form-label">{props.title}</label>
    <input
      className="form-control"
      id={props.name}
      name={props.name}
      type={props.inputtype}
      value={props.value}
      onChange={props.handlechange}
      placeholder={props.placeholder} 
      required={props.isRequired}
      readOnly={props.readOnly}
      style={props.style}
      {...props}
      />      
      {
        props.display ? 
        <input type="text" className="form-control" value={props.display}  disabled/>
        : null
      }
  </div>
)
}

export default Input;