import React, { Component } from "react";
import { Row } from "react-bootstrap";
import Input from "../../Common/Formcomponents/Input";
import axios from "axios";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { getCachedUser } from "../../authContext";
import { connect } from "react-redux";

class superAdminCreate extends Component {
  constructor(props) {
    super(props);
    let state = {};
    state = {
      user: {
        email: "",
        isSuperAdmin: true,
      },
      error: "",
    };
    this.state = state;

    this.handleInput = this.handleInput.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleInput = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState((prevState) => ({
      user: { ...prevState.user, [name]: value },
    }));
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    let url = "";
    let ishandleDriverkey = "";
    let userData = this.state.user;
    let adminUserName = this.props.adminUserName;
    url = baseURL + "admin/new";
    ishandleDriverkey = "Superadmin";
    userData["createdBy"] = adminUserName;

    axios({
      method: "post",
      url: url,
      data: userData,
      headers: {
        ClientKey: ClientKey,
        UserId: getCachedUser().preferred_username,
        AccessToken: accessToken(),
        RandomToken: this.props.refreshToken,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push({
            pathname: "/manageadmin",
            state: ishandleDriverkey,
          });
        }
      })
      .then((data) => console.log())
      .catch((error) => {
        console.log("Error detected: " + error);
        this.setState({
          error: "Superadmin ID is" + " " + error.response.data,
        });
      });
  };
  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      user: {
        name: "",
        description: "",
        distanceUom: "",
        fuelUom: "",
        language: "",
        volumeUom: "",
      },
    });
  }
  render() {
    const isEditfieldsHandled = this.props.location.state === undefined;
    return (
      <Row className="createDriver-Con">
        <h3
          className="col-12"
          style={{ color: "#000000", fontSize: "32px", marginBottom: "1.5rem" }}
        >
          Create Superadmin
        </h3>
        <div className="clearfix"></div>
        <div className="text-danger col-12">{this.state.error}</div>
        <div className="clearfix"></div>
        <form className="col-5" onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <Input
              inputtype={"email"}
              title={"Linde ID"}
              name={"email"}
              value={this.state.user.email}
              placeholder={"Enter your Linde ID"}
              handlechange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: "100%" }}
            >
              Submit
            </button>
          </div>
        </form>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(superAdminCreate);
