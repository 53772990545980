import React, { Component } from "react";
import { Row } from "react-bootstrap";
import Input from "../../Common/Formcomponents/Input";
import Select from "../../Common/Formcomponents/Select";
import axios from "axios";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { getCachedUser } from "../../authContext";
import { connect } from "react-redux";

class adminCreate extends Component {
  constructor(props) {
    super(props);
    let state = {};
    if (props.location.state !== undefined) {
      state = {
        user: {
          id: props.location.state.Id,
          email: props.location.state.Email,
          orgUnitId: props.location.state.OrgUnitId,
          isSuperAdmin: false,
        },
      };
    } else {
      state = {
        user: {
          email: "",
          orgUnitId: "",
          isSuperAdmin: false,
        },
      };
    }
    state["orgDetails"] = {};
    state["groups"] = [];
    state["error"] = "";
    this.state = state;

    this.handleInput = this.handleInput.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleInput = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState((prevState) => ({
      user: { ...prevState.user, [name]: value },
    }));
  };

  componentDidMount() {
    if (this.props.refreshToken !== "") {
      this.getGroups();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshToken !== this.props.refreshToken) {
      this.getGroups();
    }
  }

  getGroups = (orgUnit) => {
    const url = baseURL + "organizationunit/all";
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let response = json.data;
        let groups = response.map((res) => {
          return { Key: res.Name, Value: res.Id };
        });
        this.setState({
          groups,
        });
      });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    let url = "";
    let ishandleDriverkey = "";
    let userData = this.state.user;

    let adminUserName = this.props.adminUserName;
    if (this.props.location.state !== undefined) {
      url = baseURL + "admin/update";
      ishandleDriverkey = "Updated";
      userData["modifiedBy"] = adminUserName;
    } else {
      url = baseURL + "admin/new";
      ishandleDriverkey = "Created";
      userData["createdBy"] = adminUserName;
    }
    axios({
      method: "post",
      url: url,
      data: userData,
      headers: {
        ClientKey: ClientKey,
        UserId: getCachedUser().preferred_username,
        AccessToken: accessToken(),
        RandomToken: this.props.refreshToken,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push({
            pathname: "/manageadmin",
            state: ishandleDriverkey,
          });
        }
      })
      .then((data) => console.log(data))
      .catch((error) => {
        console.log("Error detected: " + error);
        this.setState({ error: "Admin ID is" + " " + error.response.data });
      });
  };

  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      user: {
        name: "",
        description: "",
        distanceUom: "",
        fuelUom: "",
        language: "",
        volumeUom: "",
      },
    });
  }
  render() {
    const isEditfieldsHandled = this.props.location.state === undefined;
    return (
      <Row className="createDriver-Con">
        <h3
          className="col-12"
          style={{ color: "#000000", fontSize: "32px", marginBottom: "1.5rem" }}
        >
          {isEditfieldsHandled ? "Create" : "Edit"} Admin
        </h3>
        <div className="clearfix"></div>
        <div className="text-danger col-12">{this.state.error}</div>
        <div className="clearfix"></div>
        <form className="col-5" onSubmit={this.handleFormSubmit}>
          {isEditfieldsHandled ? (
            <div className="form-group">
              <Input
                inputtype={"email"}
                title={"Linde ID"}
                name={"email"}
                value={this.state.user.email}
                placeholder={"Enter your Linde ID"}
                handlechange={this.handleInput}
                isRequired={true}
              />
            </div>
          ) : (
            <div className="form-group">
              <Input
                inputtype={"email"}
                title={"Linde ID"}
                name={"email"}
                value={this.state.user.email}
                placeholder={"Enter your Linde ID"}
                handlechange={this.handleInput}
                isRequired={true}
                readOnly={true}
              />
            </div>
          )}
          <div className="form-group">
            <Select
              title={"Org unit"}
              name={"orgUnitId"}
              options={this.state.groups}
              value={this.state.user.orgUnitId}
              placeholder={"Select Org unit"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: "100%" }}
            >
              Submit
            </button>
          </div>
        </form>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(adminCreate);
