import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Headerlogo from "../../assets/Layer 1.png";
import { Row, Col } from "react-bootstrap";
import "../common.css";
import axios from "axios";
import { getCachedUser } from "../../authContext";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { connect } from "react-redux";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    if (this.props.refreshToken !== "") {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.refreshToken !== "" &&
      prevProps.refreshToken !== this.props.refreshToken
    ) {
      this.getData();
    }
  }

  getData = () => {
    const token = getCachedUser();
    let adminUsertoken = token.preferred_username;
    let url = baseURL + "admin/getdetails/" + adminUsertoken;
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let response = json.data;
        let data = response;
        if (data.IsSuperAdmin !== undefined) {
          this.props.getaDminState(data.IsSuperAdmin);
        }
        if (data.Email !== undefined) {
          this.props.getadminUserName(data.Email);
        } else {
          this.props.getadminUserName("");
        }
        if (data.OrgUnitId !== undefined) {
          this.props.setOrgUnit(data.OrgUnitId);
        }
        this.setState({
          data: data,
        });
      })
      .catch((e) => {
        this.props.getadminUserName("");
        console.log("error");
      });
  };

  render() {
    let adminSidebar, isSuperadminSidebar;
    const isActive = (path, match, location) =>
      !!(match || path === location.pathname);
    if (this.state.data.Email && this.state.data.Email !== undefined) {
      if (this.state.data.IsSuperAdmin) {
        adminSidebar = (
          <ul>
            <li>
              <NavLink
                exact
                to="/"
                activeClassName="active"
                isActive={isActive.bind(this, "/")}
              >
                Manage Admin
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/manageorgunitid"
                activeClassName="active"
                isActive={isActive.bind(this, "/manageorgunitid")}
              >
                Manage Org Unit
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/createsuperadmin"
                activeClassName="active"
                isActive={isActive.bind(this, "/createsuperadmin")}
              >
                Create Superadmin
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/createblukdriver"
                activeClassName="active"
                isActive={isActive.bind(this, "/createblukdriver")}
              >
                Create Bulk driver
              </NavLink>
            </li>
          </ul>
        );
      } else {
        isSuperadminSidebar = (
          <ul>
            <li>
              <NavLink
                exact
                to="/"
                activeClassName="active"
                isActive={isActive.bind(this, "/")}
              >
                Manage Driver
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/managegroups"
                activeClassName="active"
                isActive={isActive.bind(this, "/managegroups")}
              >
                Manage Groups
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/group"
                activeClassName="active"
                isActive={isActive.bind(this, "/group")}
              >
                Create Groups
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/deletebulktrip"
                activeClassName="active"
                isActive={isActive.bind(this, "/deletebulktrip")}
              >
                Delete Bulk Trip
              </NavLink>
            </li>
          </ul>
        );
      }
    }

    return (
      <div className="Main-sidebarCon">
        <Row className="Main-sidebarCon-logo">
          <Col>
            <img src={Headerlogo} alt="Headerlogo" className="img" />
          </Col>
        </Row>
        <Row className="Main-sidebarCon-Nav">
          <Col>
            <nav>
              {adminSidebar}
              {isSuperadminSidebar}
            </nav>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};

export default connect(mapStateToProps)(Sidebar);
