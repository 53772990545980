import React from 'react';


const CheckBox = (props) => {
	return( <div className="form-group">
    <div className="checkbox">
        {
          <label className="checkbox-inline">
            <input             
              id = {props.name}
              name={props.name}
              onChange={props.handleCheckBox}
              required={props.isRequired}
              defaultChecked={props.value}
              type="checkbox" /> {}
          </label>
      }
    </div>    
    <label htmlFor={props.name} className="form-label">{props.title}</label>
  </div>
);

}

export default CheckBox;