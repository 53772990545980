import React from "react";
import SearchFilter from "../GetAllAdmin/SearchFilter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Pagination from "../GetAllDriver/Pagination";
import TrashIcon from "../../assets/Trash.svg";
import EditIcon from "../../assets/Edit.svg";
import { Row, Col } from "react-bootstrap";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { getCachedUser } from "./../../authContext";

class GetAllAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      columns: [],
      searchInput: "",
      showModal: false,
      currentRowData: {},
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    let url = baseURL + "admin/getall";
    this.getData(url);
  }

  handleDelete = (data) => {
    let url = baseURL + "admin/delete?id=" + data.Id;

    let ishandleGroupkey = data.IsSuperAdmin ? "SuperAdminDelet" : "AdminDelet";
    axios({
      method: "delete",
      url: url,
      headers: {
        ClientKey: ClientKey,
        UserId: getCachedUser().preferred_username,
        AccessToken: accessToken(),
        RandomToken: this.props.refreshToken,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push({
            pathname: "/manageadmin",
            state: ishandleGroupkey,
          });
          this.getData(baseURL + "admin/getall");
        }
      })
      .catch((error) => console.log("Error detected: " + error));
  };

  getInitialState = () => {
    return { driverStatIndicator: false };
  };

  handletoclosebar = (e) => {
    e.preventDefault();
    this.setState({ driverStatIndicator: true });
  };

  handleEdit = (data) => {
    this.props.history.push({
      pathname: "/editadmin",
      state: data,
    });
  };

  getColumns = () => {
    let columns = [
      {
        Header: "Linde ID",
        accessor: "Email",
        sortable: true,
        show: true,
        displayValue: "Linde ID",
      },
      {
        Header: "Org unit",
        accessor: "OrgUnitName",
        sortable: true,
        show: true,
        displayValue: "orgUnitId",
      },
      {
        Header: <Link to={"/createadmin"}>NEW</Link>,
        accessor: "",
        sortable: false,
        show: true,
        displayValue: "NEW",
        Cell: (row) => (
          <div>
            {row.original.OrgUnitName ? (
              <img
                onClick={() => this.handleEdit(row.original)}
                src={EditIcon}
                alt="EditIcon"
                className="img"
              />
            ) : (
              <img className="img imgNoData" />
            )}
            <img
              onClick={() => this.showModal(row.original)}
              src={TrashIcon}
              alt="TrashIcon"
              className="img"
            />
          </div>
        ),
      },
    ];
    this.setState({ columns });
  };

  getData = (url) => {
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let response = json.data;
        let data = response;
        this.setState({
          data: data,
          loading: false,
          showModal: false,
        });
        this.getColumns(data);
      });
  };

  handleSetData = (data) => {
    this.setState({ data });
  };

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
    this.props.history.push({
      pathname: "/manageadmin",
      state: "",
    });
  };
  showModal = (data) => {
    this.setState({ currentRowData: data, showModal: true });
  };

  render() {
    let { data, filteredData, columns, searchInput } = this.state;
    const dataToDisplay = searchInput.length ? filteredData : data;
    const isCheckDrvDetails = this.props.location;

    return (
      <div className="getallDriver-Con">
        {isCheckDrvDetails.state === "Updated" ||
        isCheckDrvDetails.state === "Superadmin" ||
        isCheckDrvDetails.state === "Created" ||
        isCheckDrvDetails.state === "SuperAdminDelet" ||
        isCheckDrvDetails.state === "AdminDelet" ? (
          <Row
            className={
              this.state.driverStatIndicator
                ? "hidden driverstatus-Con"
                : "driverstatus-Con"
            }
          >
            <Col>
              {isCheckDrvDetails.state === "Updated"
                ? "Admin Updated Sucessfully"
                : isCheckDrvDetails.state === "Created"
                ? "Admin Created Sucessfully"
                : isCheckDrvDetails.state === "SuperAdminDelet"
                ? "SuperAdmin Deleted Sucessfully"
                : isCheckDrvDetails.state === "AdminDelet"
                ? "Admin Deleted Sucessfully"
                : isCheckDrvDetails.state === "Superadmin"
                ? "SuperAdmin Created Sucessfully"
                : null}
              <span onClick={this.handletoclosebar}>x</span>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {/* <div className='spradmin-con'><Link to={'/createsuperadmin'}>Superadmin +</Link></div> */}
        <SearchFilter
          data={this.state.data}
          columns={this.state.columns}
          handleSetFilteredData={this.handleSetFilteredData}
          handleSetSearchInput={this.handleSetSearchInput}
        />
        <ReactTable
          noDataText=""
          data={dataToDisplay}
          columns={columns}
          defaultPageSize={8}
          className="-striped -highlight"
          PaginationComponent={Pagination}
          minRows={0}
        />

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Do you really want to delete user ?</p>
            <div style={{ textAlign: "right" }}>
              <Button
                variant="secondary"
                onClick={this.handleClose}
                style={{ borderRadius: "0" }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(this.state.currentRowData)}
                style={{ marginLeft: "15px", borderRadius: "0" }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(GetAllAdmin);
