import React from "react";
import SearchFilter from "../GetAllGroups/SearchFilter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Pagination from "../GetAllDriver/Pagination";
import TrashIcon from "../../assets/Trash.svg";
import { Row, Col } from "react-bootstrap";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { getCachedUser } from "../../authContext";
import { connect } from "react-redux";

class GetAllGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      columns: [],
      searchInput: "",
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    if (this.props.refreshToken !== "") {
      this.getGroups();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.orgUnit !== undefined &&
      prevProps.orgUnit !== this.props.orgUnit &&
      this.props.refreshToken !== ""
    ) {
      this.getGroups();
    }
    if (
      prevProps.refreshToken !== "" &&
      prevProps.refreshToken !== this.props.refreshToken &&
      this.props.orgUnit !== undefined
    ) {
      this.getGroups();
    }
  }

  getGroups = () => {
    let url = baseURL + "group/getgroup/" + this.props.orgUnit;
    this.getData(url);
  };

  handleDelete = (data) => {
    let userData = data.id;
    let url = baseURL + "group/delete/" + data.id;
    let ishandleGroupkey = "groupDeleted";
    axios({
      method: "post",
      url: url,
      headers: {
        ClientKey: ClientKey,
        UserId: getCachedUser().preferred_username,
        AccessToken: accessToken(),
        RandomToken: this.props.refreshToken,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push({
            pathname: "/managegroups",
            state: ishandleGroupkey,
            groupName: data.name,
          });
          this.getGroups();
        }
      })
      .then((data) => console.log())
      .catch((error) => console.log("Error detected: " + error));
  };
  getInitialState = () => {
    return { driverStatIndicator: false };
  };
  handletoclosebar = (e) => {
    e.preventDefault();
    this.setState({ driverStatIndicator: true });
  };

  getColumns = () => {
    let columns = [
      {
        Header: "Name",
        accessor: "Name",
        sortable: true,
        show: true,
        displayValue: "Name",
      },
      {
        Header: "Description",
        accessor: "Description",
        sortable: true,
        show: true,
        displayValue: "Description",
      },
      {
        Header: <Link to={"/group"}>NEW</Link>,
        accessor: "",
        sortable: false,
        show: true,
        displayValue: "NEW",
        Cell: (row) => (
          <div>
            {row.original.isAssociated === false ? (
              <img
                onClick={() => this.handleDelete(row.original)}
                src={TrashIcon}
                alt="TrashIcon"
                className="img"
              />
            ) : (
              ""
            )}
          </div>
        ),
      },
    ];
    this.setState({ columns });
  };

  getData = (url) => {
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let response = json.data;
        let data = response;

        this.setState({
          data: data,
          loading: false,
        });
        this.getColumns(data);
      });
  };

  handleSetData = (data) => {
    this.setState({ data });
  };

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };

  render() {
    let { data, filteredData, columns, searchInput } = this.state;
    const dataToDisplay = searchInput.length ? filteredData : data;
    const isCheckgroupDetails = this.props.location;
    return (
      <div className="content">
        <div className="getallDriver-Con">
          {isCheckgroupDetails.state === "groupDeleted" ||
          isCheckgroupDetails.state === "groupCreated" ? (
            <Row
              className={
                this.state.driverStatIndicator
                  ? "hidden driverstatus-Con"
                  : "driverstatus-Con"
              }
            >
              <Col>
                {isCheckgroupDetails.state === "groupDeleted"
                  ? "Group Deleted Sucessfully"
                  : isCheckgroupDetails.state === "groupCreated"
                  ? "Group Created Sucessfully"
                  : null}
                <span onClick={this.handletoclosebar}>x</span>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <SearchFilter
            data={this.state.data}
            columns={this.state.columns}
            handleSetFilteredData={this.handleSetFilteredData}
            handleSetSearchInput={this.handleSetSearchInput}
          />
          <ReactTable
            noDataText=""
            data={dataToDisplay}
            columns={columns}
            defaultPageSize={8}
            className="-striped -highlight"
            PaginationComponent={Pagination}
            minRows={0}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(GetAllGroups);
