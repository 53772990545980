import React from "react";
import SearchFilter from "../GetAllDriver/SearchFilter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Pagination from "../GetAllDriver/Pagination";
import EditIcon from "../../assets/Edit.svg";
import TrashIcon from "../../assets/Trash.svg";
import { Row, Col } from "react-bootstrap";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { getCachedUser } from "../../authContext";
import { connect } from "react-redux";
import CheckBox from "../../Common/Formcomponents/CheckBox";
import Loader from "../../Common/LoaderAllDriver";

class Getalldriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      columns: [],
      searchInput: "",
      checkBoxStatus: true,
      checkBoxTitle: "Active",
      showsloader: false,
    };
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    if (this.props.refreshToken !== "") {
      let url = baseURL + "driver/all";
      this.getData(url);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshToken !== this.props.refreshToken) {
      let url = baseURL + "driver/all";
      this.getData(url);
    }
  }

  handleEdit = (data) => {
    this.props.history.push({
      pathname: "/edit",
      state: data,
    });
  };
  handleTogetdriverdetails = (data) => {
    this.props.history.push({
      pathname: "/",
      state: "DD-row-details",
      firstName: data.DisplayName,
      schedulingDriverID: data.SchedulingDriverId,
      userID: data.Email,
      orgUnit: data.OrgUnitId,
      driverGroup: data.GroupName,
      language: data.LanguageName,
    });
  };
  getInitialState = () => {
    return { driverStatIndicator: false };
  };
  handletoclosebar = (e) => {
    e.preventDefault();
    this.setState({ driverStatIndicator: true });
  };

  getColumns = () => {
    let columns = [
      {
        id: "driverName",
        Header: "Driver Name",
        accessor: "FirstName",
        Cell: (props) => (
          <span onClick={() => this.handleTogetdriverdetails(props.original)}>
            {props.original.DisplayName}
          </span>
        ),
        sortable: true,
        show: true,
        displayValue: "Driver Name",
      },
      {
        Header: "User ID",
        accessor: "Email",
        sortable: true,
        show: true,
        displayValue: "User ID",
      },
      {
        Header: "Driver ID",
        accessor: "SchedulingDriverId",
        sortable: true,
        show: true,
        displayValue: "Driver ID",
      },
      {
        Header: "Driver Group",
        accessor: "GroupName",
        sortable: true,
        show: true,
        displayValue: "Driver Group",
      },
      {
        Header: "Language",
        accessor: "LanguageName",
        sortable: true,
        show: true,
        displayValue: "Language",
      },
      {
        Header: <Link to={"/create"}>NEW</Link>,
        accessor: "",
        sortable: false,
        show: true,
        displayValue: "NEW",
        Cell: (row) => (
          <div>
            <img
              onClick={() => this.handleEdit(row.original)}
              src={EditIcon}
              alt="EditIcon"
              className="img"
            />
            {row.original.isActive !== false ? (
              <img
                onClick={() => this.handleEdit(row.original)}
                src={TrashIcon}
                alt="TrashIcon"
                className="img"
              />
            ) : (
              ""
            )}
          </div>
        ),
      },
    ];
    this.setState({ columns });
  };

  getData = (url) => {
    this.setState({ showsloader: true });
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let response = json.data;
        let data = response;
        this.setState(
          {
            data: data,
            loading: false,
            showsloader: false,
          },
          () => this.getFilteredData()
        );
        this.getColumns(data);
      });
  };

  globalSearch = (updateData) => {
    const { searchInput } = this.state;

    let filteredData = updateData.filter((value) => {
      return (
        value.DisplayName.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.Email.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.SchedulingDriverId.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.GroupName.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.LanguageName.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });

    let newFilterData = filteredData.filter(
      (value) =>
        value.IsActive === this.state.checkBoxStatus &&
        value.OrgUnitId === this.props.orgUnit
    );
    this.handleSetFilteredData(newFilterData);
    this.setState({ showsloader: false });
  };
  getFilteredData = () => {
    setTimeout(() => {
      this.globalSearch(this.state.data);
    }, 1000);
  };

  handleSetData = (data) => {
    this.setState({ data });
  };

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };
  handleCheckBox = () => {
    this.setState({ showsloader: true });
    this.setState({ checkBoxStatus: !this.state.checkBoxStatus }, () =>
      this.getFilteredData()
    );
  };

  render() {
    let { data, filteredData, columns, searchInput } = this.state;
    //const dataToDisplay = searchInput.length ? filteredData : data;
    const dataToDisplay = filteredData;
    const isCheckDrvDetails = this.props.location;
    return (
      <div className="getallDriver-Con">
        {isCheckDrvDetails.state === "Updated" ||
        isCheckDrvDetails.state === "Created" ||
        isCheckDrvDetails.state === "updatepassword" ? (
          <Row
            className={
              this.state.driverStatIndicator
                ? "hidden driverstatus-Con"
                : "driverstatus-Con"
            }
          >
            <Col>
              {isCheckDrvDetails.state === "Updated"
                ? "Driver Updated Sucessfully"
                : isCheckDrvDetails.state === "Created"
                ? "Driver Created Sucessfully"
                : isCheckDrvDetails.state === "updatepassword"
                ? "Password Updated Sucessfully"
                : null}
              <span onClick={this.handletoclosebar}>x</span>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {this.state.showsloader ? <Loader /> : null}
        <SearchFilter
          data={this.state.data}
          columns={this.state.columns}
          handleSetFilteredData={this.handleSetFilteredData}
          handleSetSearchInput={this.handleSetSearchInput}
          checkBoxStatus={this.state.checkBoxStatus}
          orgUnit={this.props.orgUnit}
          globalSearch={() => this.globalSearch(this.state.data)}
        />
        <div className="driverDetailsCheckbox">
          <CheckBox
            title={this.state.checkBoxTitle}
            name={"isActive"}
            value={this.state.checkBoxStatus}
            handleCheckBox={this.handleCheckBox}
            isRequired={false}
          />
        </div>

        <ReactTable
          noDataText=""
          data={dataToDisplay}
          columns={columns}
          defaultPageSize={8}
          className="-striped -highlight"
          PaginationComponent={Pagination}
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(Getalldriver);
