import {combineReducers} from 'redux';
const initialState = {
    refreshToken:''
};

function Reducers(state = initialState,action) {
    switch(action.type){
            
        case 'SET_REFRESH_TOKEN':
            return {refreshToken:action.refreshToken};
        break;
        default :
            return state;
        break;
    }
}

export default Reducers
