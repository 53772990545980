import React, { Component } from 'react'
import {Row} from 'react-bootstrap';
import Input from '../../Common/Formcomponents/Input';  
import TextArea from '../../Common/Formcomponents/TextArea';
import axios from 'axios';
import {baseURL, ClientKey, accessToken} from '../../Services/API';
import {getCachedUser} from '../../authContext';
import {connect} from 'react-redux';

class Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getfueldropdowndata: [],
            error: ''
        };

        this.state = {
            newUser: {   
                name: '',
                description: '' 
            },    
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleInput = (e) => {
        let value = e.target.value;
        let name = e.target.name;
    this.setState( prevState => ({ newUser : 
        {...prevState.newUser, [name]: value
        }
        }), () => console.log())
    }   

    handleFormSubmit = (e) => {
        e.preventDefault();
        let url = '';
        let ishandleDriverkey = ''
        let userData = this.state.newUser;       
        url = baseURL+'group/new';
        ishandleDriverkey = 'groupCreated';
        let organizationUnitId = this.props.orgUnit;
        let createdBy = this.props.adminUserName;
        userData['createdBy'] = createdBy;
        userData['organizationUnitId'] = organizationUnitId;
        axios({
            method: 'post',
            url: url,
            data: userData,
            headers:{
                ClientKey:ClientKey,
                UserId:getCachedUser().preferred_username,
                AccessToken:accessToken(),
                RandomToken:this.props.refreshToken
            }
            }).then(response => {
                if(response.status === 200){
                    this.props.history.push({
                        pathname: '/managegroups',
                        state: ishandleDriverkey,
                    });
                }
            })
            .then(data => console.log())    
            .catch(error => {
                console.log("Error detected: " + error);
                this.setState({error:"Group Name is AlreadyExist"});
              }
            );
    }   
    render() {
        return (
            <Row className="createDriver-Con">
                <h3 className='col-12' style={{color: '#000000',fontSize: '32px',marginBottom: '1.5rem'}}>Create Groups</h3>
                <div className='clearfix'></div>
                <div className="text-danger col-12">{this.state.error}</div>
                <div className='clearfix'></div>
                <form className='col-5' onSubmit={this.handleFormSubmit}>
                    <div className="form-group">
                        <Input inputtype={'text'}
                            title= {'Group Name'} 
                            name= {'name'}
                            value={this.state.newUser.name} 
                            placeholder = {'Enter Group Name'}
                            handlechange = {this.handleInput}    
                            isRequired={true}                
                        /> 
                    </div>
                    <TextArea   
                        title= {'Description'}                    
                        name={'description'}
                        value={this.state.newUser.description}
                        placeholder = {'Please enter the description'}
                        handleInput={this.handleInput} 
                        isRequired={true} 
                        rows={70}
                        cols = {70}
                    />
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary" style={{width: '100%'}}>Submit</button>
                    </div>
                </form>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        refreshToken:state.refreshToken
    }
}
export default connect(mapStateToProps)(Group)
