import React from "react"; 
import Input from '../../Common/Formcomponents/Input';  
import SearchIcon from '../../assets/Search.svg';

let SerachsectionStyle = {
  backgroundImage: `url(${SearchIcon})`,
  backgroundSize: '20px auto',
  backgroundPosition: '96% center',
  backgroundRepeat: 'no-repeat'
};

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredData: [],
      columnSearch: "",
      searchInput: ""
    };
  }

  handleChange = event => {
    const val = event.target.value;
    this.setState({ searchInput: val }, () => this.props.globalSearch());
    this.props.handleSetSearchInput(val);
  };

  setColumnSearch = e => {
    this.setState({ columnSearch: e.target.value }, () => this.props.globalSearch());
  };

  render() {
    // const { columns } = this.props;
    // const { columnSearch } = this.state;

    return (
      <>
        <Input
          size="large"
          name="searchInput"
          value={this.state.searchInput || ""}
          onChange={this.handleChange}
          label="Search"
          placeholder="Search"
          style={SerachsectionStyle}
        />
        {/* <select
          onChange={e => {
            e.persist();
            this.setColumnSearch(e);
          }}
          value={columnSearch}
        >
          <option value=""> All columns</option>
          {columns.map(col => {
            return <option value={col.accessor}>{col.Header}</option>;
          })}
        </select>
        <br />
        <br /> */}
      </>
    );
  }
}

export default SearchFilter

