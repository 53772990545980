import React from "react";
import SearchFilter from "../GetAllGroups/SearchFilter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Pagination from "../GetAllDriver/Pagination";
import TrashIcon from "../../assets/Trash.svg";
import EditIcon from "../../assets/Edit.svg";
import { Row, Col } from "react-bootstrap";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { connect } from "react-redux";
import { getCachedUser } from "./../../authContext";

class GetAllOrgUnitID extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      columns: [],
      searchInput: "",
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    if (this.props.refreshToken !== "") {
      let url = baseURL + "organizationunit/all";
      this.getData(url);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.refreshToken !== "" &&
      prevProps.refreshToken !== this.props.refreshToken
    ) {
      let url = baseURL + "organizationunit/all";
      this.getData(url);
    }
  }

  handleDelete = (data) => {
    let url = baseURL + "organizationunit/delete?id=" + data.Id;
    let ishandleGroupkey = "groupDeleted";
    axios({
      method: "delete",
      url: url,
      headers: {
        ClientKey: ClientKey,
        UserId: getCachedUser().preferred_username,
        AccessToken: accessToken(),
        RandomToken: this.props.refreshToken,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push({
            pathname: "/manageorgunitid",
            state: ishandleGroupkey,
          });
          this.getData(baseURL + "organizationunit/all");
        }
      })
      .then((data) => console.log())
      .catch((error) => console.log("Error detected: " + error));
  };
  getInitialState = () => {
    return { driverStatIndicator: false };
  };
  handletoclosebar = (e) => {
    e.preventDefault();
    this.setState({ driverStatIndicator: true });
  };
  handleEdit = (data) => {
    this.props.history.push({
      pathname: "/editorgunitid",
      state: data,
    });
  };

  getColumns = () => {
    let columns = [
      {
        Header: "Name",
        accessor: "Name",
        sortable: true,
        show: true,
        displayValue: "Name",
      },
      {
        Header: "Description",
        accessor: "Description",
        sortable: true,
        show: true,
        displayValue: "Description",
      },
      {
        Header: <Link to={"/createorgunitid"}>NEW</Link>,
        accessor: "",
        sortable: false,
        show: true,
        displayValue: "NEW",
        Cell: (row) => (
          <div>
            <img
              onClick={() => this.handleEdit(row.original)}
              src={EditIcon}
              alt="EditIcon"
              className="img"
            />
            {row.original.isAssociated === false ? (
              <img
                onClick={() => this.handleDelete(row.original)}
                src={TrashIcon}
                alt="TrashIcon"
                className="img"
              />
            ) : (
              ""
            )}
          </div>
        ),
      },
    ];
    this.setState({ columns });
  };

  getData = (url) => {
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let response = json.data;
        let data = response;
        this.setState({
          data: data,
          loading: false,
        });
        this.getColumns(data);
      });
  };

  handleSetData = (data) => {
    this.setState({ data });
  };

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };

  render() {
    let { data, filteredData, columns, searchInput } = this.state;
    const dataToDisplay = searchInput.length ? filteredData : data;
    const isCheckDrvDetails = this.props.location;
    return (
      <div className="getallDriver-Con">
        {isCheckDrvDetails.state === "Updated" ||
        isCheckDrvDetails.state === "Created" ||
        isCheckDrvDetails.state === "groupDeleted" ? (
          <Row
            className={
              this.state.driverStatIndicator
                ? "hidden driverstatus-Con"
                : "driverstatus-Con"
            }
          >
            <Col>
              {isCheckDrvDetails.state === "Updated"
                ? "OrgUnitID Updated Sucessfully"
                : isCheckDrvDetails.state === "Created"
                ? "OrgUnitID Created Sucessfully"
                : isCheckDrvDetails.state === "groupDeleted"
                ? "OrgUnitID Deleted Sucessfully"
                : null}
              <span onClick={this.handletoclosebar}>x</span>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <SearchFilter
          data={this.state.data}
          columns={this.state.columns}
          handleSetFilteredData={this.handleSetFilteredData}
          handleSetSearchInput={this.handleSetSearchInput}
        />
        <ReactTable
          noDataText=""
          data={dataToDisplay}
          columns={columns}
          defaultPageSize={8}
          className="-striped -highlight"
          PaginationComponent={Pagination}
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(GetAllOrgUnitID);
