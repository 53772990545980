import React, { Component } from "react";
import Getalldriver from "../../Screens/GetAllDriver";
import GetAllAdmin from "../../Screens/GetAllAdmin";

class Dashboard extends Component {
  render() {
    return (
      <>
        {this.props.isSuperadmin ? (
          <GetAllAdmin {...this.props} />
        ) : (
          <Getalldriver
            {...this.props}
            orgUnit={this.props.orgUnit}
            adminUserName={this.props.adminUserName}
          />
        )}
      </>
    );
  }
}

export default Dashboard;
