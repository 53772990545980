import React, { Component } from "react";
import { Row } from "react-bootstrap";
import Input from "../../Common/Formcomponents/Input";
import Select from "../../Common/Formcomponents/Select";
import axios from "axios";
import CheckBox from "../../Common/Formcomponents/CheckBox";
import { baseURL, ClientKey, accessToken } from "../../Services/API";
import { connect } from "react-redux";
import { getCachedUser } from "../../authContext";
import { Modal, Button } from "react-bootstrap";

class Driver extends Component {
  constructor(props) {
    super(props);
    let state = {};
    if (props.location.state !== undefined) {
      state = {
        user: {
          distanceUom: props.location.state.DistanceUom,
          email: props.location.state.Email,
          firstName: props.location.state.DisplayName.split(" ")[0] || "",
          fuelUom: props.location.state.FuelUom,
          groupId: props.location.state.GroupId,
          id: props.location.state.Id,
          isActive: props.location.state.IsActive,
          languageId: props.location.state.LanguageId,
          lastName: props.location.state.DisplayName.split(" ")[1] || "",
          objectId: props.location.state.ObjectId,
          orgUnitId: props.location.state.OrgUnitId,
          password: props.location.state.Password,
          schedulingDriverId: props.location.state.SchedulingDriverId,
          volumeUom: props.location.state.VolumeUom,
          confirmPassword: props.location.state.ConfirmPassword,
        },
        showModal: false,
        showAlertinvalid: false,
        showAlertnotmatch: false,
      };
      console.log("state" + JSON.stringify(state));
    } else {
      state = {
        user: {
          distanceUom: "",
          email: "",
          firstName: "",
          fuelUom: "",
          groupId: "",
          isActive: true,
          languageId: "",
          lastName: "",
          orgUnitId: "",
          password: "",
          schedulingDriverId: "",
          volumeUom: "",
          password: "",
          confirmPassword: "",
        },
        showModal: false,
        showAlertinvalid: false,
        showAlertnotmatch: false,
      };
    }
    state["volumeUom"] = [];
    state["fuelUom"] = [];
    state["languageId"] = [];
    state["distanceUom"] = [];
    state["orgDetails"] = {};
    state["groups"] = [];
    state["error"] = [];
    this.state = state;

    this.handleInput = this.handleInput.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlegoBackbtn = this.handlegoBackbtn.bind(this);
  }
  handleInput = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState((prevState) => ({
      user: { ...prevState.user, [name]: value },
    }));
  };

  componentDidMount() {
    if (this.props.refreshToken !== "") {
      this.getConfig();
      if (this.props.orgUnit !== "") {
        this.getOrganisationDetails(this.props.orgUnit);
        this.getGroups(this.props.orgUnit);
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.orgUnit !== "" &&
      prevProps.orgUnit !== this.props.orgUnit &&
      this.props.refreshToken != ""
    ) {
      this.getOrganisationDetails(this.props.orgUnit);
      this.getGroups(this.props.orgUnit);
    }
    if (
      this.props.orgUnit !== "" &&
      prevProps.refreshToken !== this.props.refreshToken
    ) {
      this.getOrganisationDetails(this.props.orgUnit);
      this.getGroups(this.props.orgUnit);
    }
  }

  getGroups = (orgUnit) => {
    const url = baseURL + "group/getgroup/" + orgUnit;
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let response = json.data;
        let groups = response.map((res) => {
          return { Key: res.Name, Value: res.Id };
        });
        this.setState({
          groups,
        });
      });
  };

  getOrganisationDetails = (orgUnit) => {
    const url = baseURL + "organizationunit/getbyid/" + orgUnit;
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let data = json.data;
        if (this.props.location.pathname === "/create") {
          this.setState((prevState) => ({
            user: {
              ...prevState.user,
              fuelUom: data.FuelUom,
              languageId: data.Language,
              distanceUom: data.DistanceUom,
              volumeUom: data.VolumeUom,
              orgUnitId: data.Id,
            },
            orgDetails: data,
          }));
        } else {
          this.setState((prevState) => ({
            user: {
              ...prevState.user,
              orgUnitId: data.Id,
            },
            orgDetails: data,
          }));
        }
      });
  };
  getConfig = () => {
    let url = baseURL + "driver/getconfig";
    axios
      .get(url, {
        headers: {
          ClientKey: ClientKey,
          UserId: getCachedUser().preferred_username,
          AccessToken: accessToken(),
          RandomToken: this.props.refreshToken,
        },
      })
      .then((json) => {
        let response = json.data;
        let data = response;
        this.setState({
          distanceUom: data.Distance,
          fuelUom: data.Fuel,
          languageId: data.Language,
          volumeUom: data.Volume,
        });
      });
  };

  handleCheckBox = () => {
    this.setState((prevState) => ({
      user: { ...prevState.user, isActive: !prevState.user.isActive },
    }));
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    let url = "";
    let ishandleDriverkey = "";
    let userData = this.state.user;

    let adminUserName = this.props.adminUserName;
    if (this.props.location.state !== undefined) {
      url = baseURL + "driver/update";
      // url = baseURL+'driver/updatepassword';
      ishandleDriverkey = "Updated";
      userData["modifiedBy"] = adminUserName;
    } else {
      url = baseURL + "driver/new";
      ishandleDriverkey = "Created";
      userData["createdBy"] = adminUserName;
    }
    console.log(
      "userData??=>",
      userData,
      url,
      getCachedUser().preferred_username,
      "==",
      accessToken()
    );
    axios({
      method: "post",
      url: url,
      data: userData,
      headers: {
        ClientKey: ClientKey,
        UserId: getCachedUser().preferred_username,
        AccessToken: accessToken(),
        RandomToken: this.props.refreshToken,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push({
            pathname: "/",
            state: ishandleDriverkey,
            firstName: userData.firstName,
            lastName: userData.lastName,
          });
        }
      })
      .then((data) => console.log())
      .catch((error) => {
        console.log("Error detected: " + error);
        this.setState({ error: error.response.data });
      });
  };
  //for password

  //for change password
  handleFormSubmit1 = (e) => {
    e.preventDefault();
    let url = "";
    let ishandleDriverkey = "";
    let userData = this.state.user;
    console.log("USERDATE" + JSON.stringify(userData));
    //  const { password, confirmPassword } = this.state;
    let password = this.state.user.password;
    let confirmPassword = this.state.user.confirmPassword;

    let adminUserName = this.props.adminUserName;
    let pass = this.state.user.password;

    let regex = /^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/;
    if (regex.exec(pass) == null) {
      this.setState({ showAlertinvalid: true });
      alert(
        "Must contain at least one number and one special characters , and at least 8 or more characters"
      );
    } else {
      this.setState({ showAlertinvalid: false });

      if (password !== confirmPassword) {
        this.setState({ showAlertnotmatch: true });
        //  alert("Passwords don't match");
      } else {
        this.setState({ showAlertnotmatch: false });
        if (this.props.location.state !== undefined) {
          //url = baseURL+'driver/update';
          url = baseURL + "driver/updatepassword";
          ishandleDriverkey = "updatepassword";
          userData["modifiedBy"] = adminUserName;
        } else {
          url = baseURL + "driver/new";
          ishandleDriverkey = "Created";
          userData["createdBy"] = adminUserName;
        }

        axios({
          method: "post",
          url: url,
          data: userData,
          headers: {
            ClientKey: ClientKey,
            UserId: getCachedUser().preferred_username,
            AccessToken: accessToken(),
            RandomToken: this.props.refreshToken,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              this.props.history.push({
                pathname: "/",
                state: ishandleDriverkey,
                firstName: userData.firstName,
                lastName: userData.lastName,
              });
            }
          })
          .then((data) => console.log())
          .catch((error) => {
            console.log("Error detected: " + error);
            this.setState({ error: error.response.data });
          });
      }
    }
  };

  /////

  handlegoBackbtn = (e) => {
    this.props.history.push({
      pathname: "/",
    });
  };
  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      user: {
        distanceUom: "",
        email: "",
        firstName: "",
        fuelUom: "",
        groupId: "",
        id: "",
        isActive: "",
        languageId: "",
        lastName: "",
        objectId: "",
        orgUnitId: "",
        password: "",
        schedulingDriverId: "",
        volumeUom: "",
      },
    });
  }

  handleClose = () => {
    this.setState({
      showModal: false,
      showAlertinvalid: false,
      showAlertnotmatch: false,
    });
    this.props.history.push({
      pathname: "/edit",
      state: "",
    });
  };
  showModal = (data) => {
    this.setState({ currentRowData: data, showModal: true });
  };

  render() {
    const isEditfieldsHandled = this.props.location.state === undefined;
    const showAlertinvalid = this.state.showAlertinvalid;
    const showAlertnotmatch = this.state.showAlertnotmatch;
    console.log("log" + JSON.stringify(this.state.user));
    return (
      <Row className="createDriver-Con">
        <h3
          className="col-12"
          style={{ color: "#000000", fontSize: "32px", marginBottom: "1.5rem" }}
        >
          {isEditfieldsHandled ? "Create" : "Edit"} Driver
        </h3>
        <div className="clearfix"></div>
        <div className="text-danger col-12">{this.state.error}</div>
        <div className="clearfix"></div>
        <form className="col-5" onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <Input
              inputtype={"text"}
              title={"Driver ID"}
              name={"schedulingDriverId"}
              value={this.state.user.schedulingDriverId}
              placeholder={"Enter your Driver ID"}
              handlechange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <Input
              inputtype={"text"}
              title={"First Name"}
              name={"firstName"}
              value={this.state.user.firstName}
              placeholder={"First Name"}
              handlechange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <Input
              inputtype={"text"}
              title={"Last Name"}
              name={"lastName"}
              value={this.state.user.lastName}
              placeholder={"Last Name"}
              handlechange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <Input
              inputtype={"hidden"}
              title={"Org Unit"}
              name={"orgUnitId"}
              value={
                this.state.user.orgUnitId
                  ? this.state.user.orgUnitId
                  : this.props.orgUnit
              }
              handlechange={this.handleInput}
              isRequired={true}
              display={this.state.orgDetails.name}
              readOnly={true}
            />
          </div>
          {isEditfieldsHandled ? (
            <div className="form-group">
              <Input
                inputtype={"email"}
                title={"User ID"}
                name={"email"}
                value={this.state.user.email}
                placeholder={"@drivercompanion.linde.com"}
                handlechange={this.handleInput}
                isRequired={true}
              />
            </div>
          ) : (
            <div className="form-group">
              <Input
                inputtype={"email"}
                title={"User ID"}
                name={"email"}
                value={this.state.user.email}
                placeholder={"@drivercompanion.linde.com"}
                handlechange={this.handleInput}
                isRequired={true}
                readOnly={true}
              />
            </div>
          )}
          {isEditfieldsHandled ? (
            <div className="form-group">
              <Input
                inputtype={"password"}
                title={"User password"}
                name={"password"}
                value={this.state.user.password}
                placeholder={"Enter your Password"}
                handlechange={this.handleInput}
                isRequired={true}
              />
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <Select
              title={"Driver Group"}
              name={"groupId"}
              options={this.state.groups}
              value={this.state.user.groupId}
              placeholder={"Select Group"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <Select
              title={"Language"}
              name={"languageId"}
              options={this.state.languageId}
              value={
                this.state.user.languageId !== ""
                  ? this.state.user.languageId
                  : this.state.orgDetails.language
              }
              placeholder={"Select Language"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <Select
              title={"Fuel UOM"}
              name={"fuelUom"}
              options={this.state.fuelUom}
              value={
                this.state.user.fuelUom
                  ? this.state.user.fuelUom
                  : this.state.orgDetails.fuelUom
              }
              placeholder={"Select Fuel"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>
          <div className="form-group">
            <Select
              title={"Distance UOM"}
              name={"distanceUom"}
              options={this.state.distanceUom}
              value={
                this.state.user.distanceUom
                  ? this.state.user.distanceUom
                  : this.state.orgDetails.distanceUom
              }
              placeholder={"Select Distance"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>

          <div className="form-group">
            <Select
              title={"Quantity UOM"}
              name={"volumeUom"}
              options={this.state.volumeUom}
              value={
                this.state.user.volumeUom
                  ? this.state.user.volumeUom
                  : this.state.orgDetails.volumeUom
              }
              placeholder={"Select Quantity"}
              handleChange={this.handleInput}
              isRequired={true}
            />
          </div>
          <CheckBox
            title={"Is Active"}
            name={"isActive"}
            value={this.state.user.isActive}
            handleCheckBox={this.handleCheckBox}
            isRequired={false}
          />

          <div className="form-group">
            <button
              type="submit"
              className="btn btn-warning"
              onClick={this.handlegoBackbtn}
              style={{ width: "100%", marginBottom: "15px" }}
            >
              Back
            </button>
            <button
              type="submit"
              onClick={this.handleFormSubmit.bind(this)}
              className="btn btn-primary"
              style={{ width: "100%", marginBottom: "15px" }}
            >
              Submit
            </button>
          </div>
          <div className="form-group"></div>

          <div className="form-group">
            {isEditfieldsHandled ? (
              ""
            ) : (
              <label
                className="btn btn-outline-success"
                value={this.state.user.password}
                onClick={() => this.showModal(true)}
                style={{ width: "100%", marginBottom: "15px" }}
              >
                Change Password
              </label>
            )}
          </div>
          <Modal show={this.state.showModal} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label>
                Password must be eight characters including one uppercase letter
                and one number
              </label>
              <Input
                inputtype={"password"}
                title={"New Password"}
                name={"password"}
                value={this.state.user.password}
                placeholder={"New Password"}
                handlechange={this.handleInput}
                isRequired={true}
              />
              <Input
                inputtype={"password"}
                title={"New Confirm Password"}
                name={"confirmPassword"}
                value={this.state.user.confirmPassword}
                placeholder={"New Confirm Password"}
                handlechange={this.handleInput}
                isRequired={true}
              />
              {showAlertinvalid ? (
                <div class="alert alert-danger">
                  Password must be eight characters including one uppercase
                  letter and one number
                </div>
              ) : (
                ""
              )}
              {showAlertnotmatch ? (
                <div class="alert alert-danger">
                  Password and confirm password does not match
                </div>
              ) : (
                ""
              )}

              <div style={{ textAlign: "right" }}>
                <Button
                  variant="secondary"
                  onClick={this.handleClose}
                  style={{ borderRadius: "0" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={this.handleFormSubmit1.bind(this)}
                  style={{ marginLeft: "15px", borderRadius: "0" }}
                >
                  Change Password
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </form>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    refreshToken: state.refreshToken,
  };
};
export default connect(mapStateToProps)(Driver);
