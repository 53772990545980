import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Header from "./Common/Header";
import Sidebar from "./Common/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./Screens/Dashboard";
import Driver from "./Screens/Driver";
import GetAllGroups from "./Screens/GetAllGroups";
import Group from "./Screens/Groups";
import DriverdetailsCon from "./Screens/GetAllDriver/DriverdetailsCon";
import GetAllOrgUnitID from "./Screens/GetAllOrgUnitID";
import OrgUnitIdCreate from "./Screens/OrganizationUnit";
import GetAllAdmin from "./Screens/GetAllAdmin";
import AdminCreate from "./Screens/Admin";
import SuperadminCreate from "./Screens/Admin/Superadmin";
import CreateBulkDriver from "./Screens/Driver/Bulkdriver";
import DeleteBulkTrip from "./Screens/Driver/Bulktrip";
import { Container, Row, Col } from "react-bootstrap";
import "../src/Common/common.css";
import Loader from "./Common/Loader";
import axios from "axios";
import { getCachedUser } from "./authContext";
import { baseURL, ClientKey, accessToken } from "../src/Services/API";
import store from "./store";

class MainApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgUnit: "",
      adminUserName: null,
      isSuperadmin: "",
      refreshToken: "",
    };
  }

  componentDidMount() {
    this.login();
  }

  login = () => {
    axios({
      method: "post",
      url: `${baseURL}token/login`,
      headers: {
        ClientKey: ClientKey,
        UserId: getCachedUser().preferred_username,
        AccessToken: accessToken(),
      },
    })
      .then((res) => {
        store.dispatch({ type: "SET_REFRESH_TOKEN", refreshToken: res.data });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getaDminState = (isSuperadmin) => {
    this.setState({
      isSuperadmin,
    });
  };
  setOrgUnit = (orgUnit) => {
    this.setState({
      orgUnit,
    });
  };
  getadminUserName = (adminUserName) => {
    this.setState({
      adminUserName,
    });
  };

  render() {
    return (
      <Container>
        <Row className="MainCon-outr">
          <Router>
            <Col xs={2} className="MainCon-sidebar">
              <Sidebar
                setOrgUnit={this.setOrgUnit}
                getadminUserName={this.getadminUserName}
                getaDminState={this.getaDminState}
              />
            </Col>
            <Col>
              <Col xs={12}>
                <Header adminUserName={this.state.adminUserName} />
              </Col>
              <Col xs={8}>
                <div className="content">
                  {this.state.adminUserName === null ? (
                    <Loader />
                  ) : this.state.adminUserName === "" ? (
                    <Row className="createDriver-Con">
                      <h3
                        className="col-12"
                        style={{
                          animation: "loading 10s linear",
                          color: "red",
                          fontSize: "32px",
                          marginBottom: "1.5rem",
                        }}
                      >
                        User does not have role to Access
                      </h3>
                    </Row>
                  ) : (
                    <Switch>
                      <Route
                        path="/"
                        exact
                        render={(props) => (
                          <Dashboard
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                            isSuperadmin={this.state.isSuperadmin}
                          />
                        )}
                      />
                      <Route
                        path="/managegroups"
                        exact
                        render={(props) => (
                          <GetAllGroups
                            {...props}
                            orgUnit={this.state.orgUnit}
                          />
                        )}
                      />
                      <Route
                        path="/group"
                        exact
                        render={(props) => (
                          <Group {...props} orgUnit={this.state.orgUnit} />
                        )}
                      />
                      <Route
                        path="/create"
                        exact
                        render={(props) => (
                          <Driver
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/edit"
                        exact
                        render={(props) => (
                          <Driver
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/manageorgunitid"
                        exact
                        render={(props) => (
                          <GetAllOrgUnitID
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/createorgunitid"
                        exact
                        render={(props) => (
                          <OrgUnitIdCreate
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/editorgunitid"
                        exact
                        render={(props) => (
                          <OrgUnitIdCreate
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/manageadmin"
                        exact
                        render={(props) => (
                          <GetAllAdmin
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/createadmin"
                        exact
                        render={(props) => (
                          <AdminCreate
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/editadmin"
                        exact
                        render={(props) => (
                          <AdminCreate
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/createsuperadmin"
                        exact
                        render={(props) => (
                          <SuperadminCreate
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/createblukdriver"
                        exact
                        render={(props) => (
                          <CreateBulkDriver
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                      <Route
                        path="/deletebulktrip"
                        exact
                        render={(props) => (
                          <DeleteBulkTrip
                            {...props}
                            orgUnit={this.state.orgUnit}
                            adminUserName={this.state.adminUserName}
                          />
                        )}
                      />
                    </Switch>
                  )}
                </div>
              </Col>

              <Col xs={4}>
                <Route
                  path="/"
                  exact
                  render={(props) => <DriverdetailsCon {...props} />}
                />
              </Col>
            </Col>
          </Router>
        </Row>
      </Container>
    );
  }
}

// here app catches the suspense from page in case translations are not yet loaded
export default function App(props) {
  return <MainApp />;
}
